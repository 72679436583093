export function decodeServer(server) {
  return {
    type: '@data/DECODE_SERVER',
    payload: { server },
  };
}

export function decodedServer(server) {
  return {
    type: '@data/DECODED_SUCCESS',
    payload: { server },
  };
}

export function comandasRequest() {
  return {
    type: '@data/COMANDAS_REQUEST',
  };
}

export function comandasFailure() {
  return {
    type: '@data/COMANDAS_FAILURE',
  };
}

export function comandasSuccess(comandas) {
  return {
    type: '@data/COMANDAS_SUCCESS',
    payload: { comandas },
  };
}

export function comandaRequest(comanda) {
  return {
    type: '@data/COMANDA_REQUEST',
    payload: { comanda },
  };
}

export function comandaItemsRequest(comanda) {
  return {
    type: '@data/COMANDA_ITEMS_REQUEST',
    payload: { comanda },
  };
}

export function comandaOrdersRequest(comanda) {
  return {
    type: '@data/COMANDA_ORDERS_REQUEST',
    payload: { comanda },
  };
}

export function comandaAuditsRequest(comanda) {
  return {
    type: '@data/COMANDA_AUDITS_REQUEST',
    payload: { comanda },
  };
}

export function comandaFailure() {
  return {
    type: '@data/COMANDA_FAILURE',
  };
}

export function comandaSuccess(comanda) {
  return {
    type: '@data/COMANDA_SUCCESS',
    payload: { comanda },
  };
}

export function categoriesRequest() {
  return {
    type: '@data/CATEGORIES_REQUEST',
  };
}

export function categoriesFailure() {
  return {
    type: '@data/CATEGORIES_FAILURE',
  };
}

export function categoriesSuccess(categories) {
  return {
    type: '@data/CATEGORIES_SUCCESS',
    payload: { categories },
  };
}

export function itemsRequest(category) {
  return {
    type: '@data/ITEMS_REQUEST',
    payload: { category },
  };
}

export function itemsFailure() {
  return {
    type: '@data/ITEMS_FAILURE',
  };
}

export function itemsSuccess(items) {
  return {
    type: '@data/ITEMS_SUCCESS',
    payload: { items },
  };
}

export function closeComandaRequest(parameters) {
  return {
    type: '@data/CLOSE_COMANDA_REQUEST',
    payload: { parameters },
  };
}

export function closeComandaFailure() {
  return {
    type: '@data/CLOSE_COMANDA_FAILURE',
  };
}

export function closeComandaSuccess(parameters) {
  return {
    type: '@data/CLOSE_COMANDA_SUCCESS',
    payload: { parameters },
  };
}

export function reopenComandaRequest(parameters) {
  return {
    type: '@data/REOPEN_COMANDA_REQUEST',
    payload: { parameters },
  };
}

export function reopenComandaFailure() {
  return {
    type: '@data/REOPEN_COMANDA_FAILURE',
  };
}

export function reopenComandaSuccess(parameters) {
  return {
    type: '@data/REOPEN_COMANDA_SUCCESS',
    payload: { parameters },
  };
}
