import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    bottom: 0,
    left: 0,
    position: 'fixed',
    zIndex: 1001,
  },
  add: {
    width: '100%',
    minHeight: '80px',
    margin: 0,
    padding: '20px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    filter: 'drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.25))',
    background: '#FF7A01',
    color: '#fff',
    textTransform: 'uppercase',
  },
});

export default useStyles;
