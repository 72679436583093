import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '30px 20px',

    '& h1': {
      textTransform: 'uppercase',
      fontSize: '1rem',
      fontWeight: 600,
      marginBottom: '20px',
    },
  },
  amount: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '2px solid #eee',
    borderRadius: '10px',
    marginBottom: '20px',

    '& span': {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
  },
  plus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',

    '& span': {
      minWidth: '85px',
      padding: '20px',
      border: '2px solid #eee',
      borderRadius: '10px',
      fontSize: '1.25rem',
      fontWeight: 400,
    },
  },
  button: {
    width: '100%',
    height: '70px',
    background: 'green',
    border: 'none',
    borderRadius: '10px',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: '#fff',
  },
}));

export default useStyles;
